:root {
  --pixel-size: 8px;

  /* Backgrounds */
  --primary-background: #faf8ef;
  --secondary-background: #bbada0;
  --cell-background: #cac1b5;
  --tile-background: #eee4da;

  /* Colors */
  --primary-text-color: #776e65;
  --secondary-text-color: #f9f6f2;
}

/* @media (max-width: 748px) {
  :root {
    --pixel-size: 4px;
  }
} */


a:hover,
a:focus {
  opacity: 0.8;
}

h1 {
  font-size: calc(var(--pixel-size) * 5);
  line-height: calc(var(--pixel-size) * 5);
  margin: calc(var(--pixel-size) * 1.5) calc(var(--pixel-size) * 0);
}

h2 {
  font-size: calc(var(--pixel-size) * 2.5);
  line-height: calc(var(--pixel-size) * 3.75);
  margin: calc(var(--pixel-size) * 2.5) calc(var(--pixel-size) * 0);
}

h2 > span {
  display: block;
}

@media (min-width: 748px) {
  h1 {
    font-size: calc(var(--pixel-size) * 8.5);
    line-height: calc(var(--pixel-size) * 8.5);
    margin: calc(var(--pixel-size) * 2.25) calc(var(--pixel-size) * 0);
  }

  h2 > span {
    display: inline;
  }
}
